<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Türen&Fenster <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Graute - Graute Tronic RC3
      </h2>
      <div class="grid grid-cols-2">
        <figure class="col-span-full md:col-span-1">
          <img
            src="../assets/img/graute/ausstellung.webp"
            alt="graute tronic rc3 "
            class="w-full"
          />
        </figure>

        <div class="col-span-full md:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Sicherheit und Komfort
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Die motorisch gesteuerte 7-Punkt-Verriegelung GrauteTronic ist oben
            und unten mit je einem stabilen Stahl-Schwenkriege ausgerüstet.
            Weiterhin beinhaltet die Verriegelung oben und unten je zwei
            Stahl-Rundbolzen sowie einen rechteckigen Stahlriegel in der Mitte.
            <br /><br />
            <span class="py-3 text-xl">
              Geprüfte Sicherheit Einbruchhemmung RC 2 und RC 3 nach DIN EN 1627
            </span>
            <br />
            GRAUTE-Aluminiumtüren erfüllen in allen drei Profilsystemen sehr
            hohe Sicherheitsstandards und sind auf Wunsch auch in RC 2
            lieferbar. GRAUTE-Therm-80-Türen sind sogar in RC3 lieferbar und
            bieten so eine extra hohe Sicherheit.<br /><br />
            Die drei verschiedenen selbst entwickelten thermisch getrennte
            Profilsysteme mit bis zu 5 Kammern, Füllungen mit einem
            hochgedämmten Polyurethan-Dämmkern und Wärmeschutzglas auch als
            3-Fach-Glas mit warmer Kante gewähren einen sehr geringen
            Energieverlust und UD-Werte bis zu 0,65 W/m²K.
          </p>
        </div>
      </div>
      <div class="p-4">
        <div class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</div>
        <tabs :mode="mode">
          <tab title="Ansteuerung">
            <p class="text-lg my-2">
              Die Ansteuerung kann über einen Funkhandsender, einen
              Transponderschlüssel oder über einen Fingerscanner erfolgen.
            </p>
            <div class="flex flex-col sm:flex-row gap-4 justify-center">
              <div>
                <img
                  src="../assets/img/graute/graute-1.webp"
                  alt="graute tuer funkhandsender"
                />Funkhandsender
              </div>
              <div>
                <img
                  src="../assets/img/graute/graute-2.webp"
                  alt="graute tuer transpondersystem"
                />Transpondersystem
              </div>
              <div>
                <img
                  src="../assets/img/graute/graute-3.webp"
                  alt="graute tuer fingerscanner"
                />Fingerscanner
              </div>
            </div>
          </tab>
          <tab title="Extras">
            <p class="text-lg">
              Gaute - Türen & Fenster sind in verschiedenen Farben und
              Ausführungen erhältich. <br />
              Besuchen Sie unsere Ausstellung oder lassen Sie sich beraten.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";

export default {
  components: {
    Tab,
    Tabs,
  },
};
</script>
<style>
button:focus {
  color: #f27405;
}
button::-moz-focus-inner {
  border: 0;
}
</style>
