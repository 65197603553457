<template>
  <section>
    <h2 class="uppercase lg:pl-28 pl-4 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Solarrollladen
    </h2>
    <article class="container mx-auto p-12 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Solarrollladen
      </h2>
      <figure class="w-full my-2 flex flex-row justify-center space-x-4">
        <img
          class="h-64"
          src="../assets/img/service/solar2.webp"
          alt="berliner solarschutz rolladen"
        />
        <img
          class="hidden md:block h-72"
          src="../assets/img/service/solar3.webp"
          alt="berliner solarrolladen "
        />
      </figure>
      <div class="lg:mx-32 mx-4 my-12 text-gray-600">
        Solarrollladen nutzen für den Antrieb die natürliche Kraft der Sonne und
        sind die ideale Lösung für 90% aller Fenster und Türen. Gegenüber einem
        herkömmlichen Rollladen, der mit 230 Volt Wechselstrom betrieben wird,
        besitzen sie viele Vorteile: So kommen sie etwa dort zum Einsatz, wo
        keine Stromleitungen verlegt werden sollen, können oder dürfen.<br /><br />
        Rollladen mit Solar Antrieb besitzen ein Solarpanel, das seine Energie
        aus Sonnenstrahlen gewinnt. Diese Sonnenenergie fließt wiederum in einen
        Akku und wird dort gespeichert – daher kann man Solarrollladen auch als
        Akkurollladen bezeichnen. Bei einem Solarrollladen wird der Motor mit 12
        Volt Gleichstrom betrieben: Bei 12 Volt Gleichstrom handelt es sich um
        eine relativ ungefährliche Stromvariante, sodass für Arbeiten am
        Rollladen kein Elektriker erforderlich ist. <br /><br />
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Einfache und schnelle Montage<br /></div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Schmutzfreie Montaget<br /></div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Keine Kabelverlegung<br /></div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Energie- und Stromeinsparung<br /></div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>
            Perfekte AusführungAuch bei Stromausfall funktionsfähig<br />
          </div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Umweltschonend<br /></div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Verschiedene Ausführungen und Farben erhältlich<br /></div>
        </div>
      </div>
      <contacticons></contacticons>
    </article>
  </section>
</template>
<script>
import contacticons from "../components/contact";
export default {
  name: "App",
  components: {
    contacticons,
  },
};
</script>
